<template>
  <div id="app">
    <footer>
      <div class="container mb-5">
        <div class="row">
          <div class="col-12">
            <!-- Section Heading Area -->
            <div class="subscribe-heading text-center">
              <!-- <h2>Biosoft</h2> -->
              <router-link to="/">
                <a style="color: white;font-weight: 700;font-size: 30px;">
                  <img
                    class="logo-sticky"
                    src="assets/images/logo.png"
                    alt="logo"
                    style="height: 50px;"
                  />
                  iosoft</a
                >
              </router-link>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-3 col-md-6">
            <div class="subscribe-heading text-white">
              <h3>Services</h3>
              <h6><router-link to="/" class="link">Home</router-link></h6>
              <!-- <h6>
                <router-link to="/" class="link"
                  >Download Software</router-link
                >
              </h6>
              <h6>
                <router-link to="/" class="link"
                  >System Requirement</router-link
                >
              </h6> -->
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="subscribe-heading text-white">
              <h3>Products</h3>
              <h6>
                <router-link to="/" class="link">Windows App</router-link>
              </h6>
              <h6>
                <router-link to="/" class="link">Web App</router-link>
              </h6>
              <h6>
                <router-link to="/" class="link"
                  >Mobile App</router-link
                >
              </h6>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="subscribe-heading text-white">
              <h3>Contact Us</h3>
              <h6>
                <router-link to="/About" class="link">About Us</router-link>
              </h6>
              <h6>
                <router-link to="/Contacts" class="link">Contact</router-link>
              </h6>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="subscribe-heading text-white">
              <h3>Working Hours</h3>
              <h6 class="text-white">Mon-Fri 08:30 – 17:30</h6>
              <h6 class="text-white">© 2021 HPS Intelligence Co.,Ltd.</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 col-md-6">
            <div class="row">
              <div class="col-4 col-sm-3">
                <div class="subscribe-heading text-white">
                  <v-lazy-image
                    src="assets/images/qr_biosoft.jpg"
                    alt="Biosoft QR Code"
                    style="width: 150px;"
                  />
                </div>
              </div>
              <div class="col-8 col-sm-6">
                <div class="subscribe-heading text-white">
                  <h6 class="text-white">LINE ID: @biosoft</h6>
                  <div
                    class="app-download-area"
                    style="margin-top: 20px;margin-bottom: 20px;"
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.nextstorymobile"
                      target="_blank"
                      style="margin-right: 10px;"
                    >
                      <img
                        src="assets/images/Biosoft_GooglePlay.png"
                        alt=""
                        style="width: 350px;"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/th/app/biosoft-plus/id1588614492"
                      target="_blank"
                    >
                      <img
                        src="assets/images/Biosoft_AppStore.png"
                        alt=""
                        style="width: 320px;"
                      />
                    </a>
                  </div>
                  <ul class="social-icons list-inline">
                    <li class="list-inline-item text-center">
                      <a href="https://th-th.facebook.com/Salebiosoft/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <!-- <li class="list-inline-item text-center">
                      <a href="#"><i class="fab fa-line"></i></a>
                    </li> -->
                    <li class="list-inline-item text-center">
                      <a href="https://www.instagram.com/biosoftnext/" target="_blank"><i class="fab fa-instagram"></i></a>
                    </li>
                    <li class="list-inline-item text-center">
                      <a href="https://www.youtube.com/watch?v=h2KAMCfNs4Q&list=PLHSckEZOSob7w0N9Zs8-6wLoWkEwtvPyO" target="_blank"><i class="fab fa-youtube"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="subscribe-heading text-white">
              <h3>Sale</h3>
              <h6 class="text-white">marketing@biosoftplus.com</h6>
              <h6 class="text-white">Tel : 02-054-4800</h6>
              <h6 class="text-white">Mobile : 063-464 7567</h6>
              <h6 class="text-white">Mobile : 063-464-7568</h6>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="subscribe-heading text-white">
              <h3>Support</h3>
              <h6 class="text-white">support@biosoftplus.com</h6>
              <h6 class="text-white">Tel : 02-054-4800</h6>
              <h6 class="text-white">Mobile : 063-464 7571</h6>
              <h6 class="text-white">Mobile : 063-464 7572</h6>
            </div>
          </div>
        </div>
      </div>

      <p class="copy-right text-center">
        Copyright © 2021 HPS INTELLIGENCE Co., Ltd. All rights reserved.
      </p>
    </footer>
    <!-- ***** footer-Area-End ***** -->
    <!-- ***** footer-Area-End ***** -->
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>
<style scoped>
.text-white {
  color: whitesmoke;
}
.text-white .link {
  color: whitesmoke;
}
.text-white .link:hover {
  color: #41c9a2;
}
</style>
